import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Guards
import { AuthGuard } from './guards/auth/auth.guard';

// Import View Components
import { RemoteAccessComponent } from './modules/authentication/remote-access/remote-access.component';
import { SetClientComponent } from './modules/authentication/set-client/set-client.component';
import { SignInComponent } from './modules/authentication/sign-in/sign-in.component';
import { ResetPasswordComponent } from './modules/authentication/reset-password/reset-password.component';
import { SetPasswordComponent } from './modules/authentication/set-password/set-password.component';

import { DashboardComponent } from './modules/dashboard/dashboard.component';

import { ReportComponent } from './modules/report/report.component';
import { ReportDataComponent } from './modules/report/report-data/report-data.component';

// import { MarketingComponent } from './modules/marketing/marketing.component';
// import { DashboardComponent } from './modules/marketing/dashboard/dashboard.component';
// import { CommunicationComponent } from './modules/marketing/communication/communication.component';

import { VirtualTerminalComponent } from './modules/virtual-terminal/virtual-terminal.component';

import { PurchaseComponent } from './modules/purchase/purchase.component';
import { FulfillmentComponent } from './modules/fulfillment/fulfillment.component';
import { AdminComponent } from './modules/admin/admin.component';

import { DetailAccountComponent } from './modules/report/detail-account/detail-account.component';
import { DetailBatchComponent } from './modules/report/detail-batch/detail-batch.component';
import { DetailCustomerComponent } from './modules/report/detail-customer/detail-customer.component';
import { DetailDiscountComponent } from './modules/report/detail-discount/detail-discount.component';
import { DetailDynamicComponent } from './modules/report/detail-dynamic/detail-dynamic.component';
import { DetailInventoryComponent } from './modules/report/detail-inventory/detail-inventory.component';
import { DetailInventoryBasicComponent } from './modules/report/detail-inventory-basic/detail-inventory-basic.component';
import { DetailLocationComponent } from './modules/report/detail-location/detail-location.component';
import { DetailVendorComponent } from './modules/report/detail-vendor/detail-vendor.component';
import { DetailPurchaseComponent } from './modules/report/detail-purchase/detail-purchase.component';
import { DetailReportComponent } from './modules/report/detail-report/detail-report.component';
import { DetailTransactionComponent } from './modules/report/detail-transaction/detail-transaction.component';
import { DetailUserComponent } from './modules/report/detail-user/detail-user.component';
import { DetailUserAuthorizationComponent } from './modules/report/detail-user-authorization/detail-user-authorization.component';
import { DetailUserGroupComponent } from './modules/report/detail-user-group/detail-user-group.component';
import { DetailFormComponent } from './modules/report/detail-form/detail-form.component';
import { DetailTaxComponent } from './modules/report/detail-tax/detail-tax.component';
import { DetailPermissionComponent } from './modules/report/detail-permission/detail-permission.component';
import { DetailGroupComponent } from './modules/report/detail-group/detail-group.component';
import { DetailEmployeeComponent } from './modules/report/detail-employee/detail-employee.component';
import { DetailTerminalComponent } from './modules/report/detail-terminal/detail-terminal.component';
import { DetailPermissionGroupComponent } from './modules/report/detail-permission-group/detail-permission-group.component';
import { DetailReturnTypeComponent } from './modules/report/detail-return-type/detail-return-type.component';
import { DetailVendorInventoryComponent } from './modules/report/detail-vendor-inventory/detail-vendor-inventory.component';

// import { ConversationsComponent } from './modules/report/conversations/conversations.component';
// import { SettingsComponent } from './modules/marketing/settings/settings.component';
// import { BirthdayComponent } from './modules/marketing/birthday/birthday.component';
// import { AnniversaryComponent } from './modules/marketing/anniversary/anniversary.component';
// import { AutoresponderComponent } from './modules/marketing/autoresponder/autoresponder.component';

import { TemplateComponent } from './modules/template/template.component';
import { TransferComponent } from './modules/transfer/transfer.component';
import { DetailMenuActionComponent } from './modules/report/detail-menu-action/detail-menu-action.component';
import { DetailDiscountGroupComponent } from './modules/report/detail-discount-group/detail-discount-group.component';
import { DiscountWizardComponent } from './modules/report/discount-wizard/discount-wizard.component';

// import { InventoryComponent } from './modules/inventory/inventory.component';
// import { CountComponent } from './modules/count/count.component';


const routes: Routes = [
  {
    path: 'set-client',
    component: SetClientComponent
  },
  {
    path: 'remote-access',
    component: RemoteAccessComponent
  },
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: ReportComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':key',
        component: ReportDataComponent,
        children: [
          // {
          //   path: 'conversations',
          //   component: ConversationsComponent
          // },
          {
            path: 'account/:documentId',
            component: DetailAccountComponent
          },
          {
            path: 'batch/:documentId',
            component: DetailBatchComponent
          },
          {
            path: 'customer/:documentId',
            component: DetailCustomerComponent
          },
          {
            path: 'discount/:documentId',
            component: DetailDiscountComponent
          },
          {
            path: 'dynamic/:documentId',
            component: DetailDynamicComponent
          },
          {
            path: 'inventory/:documentId',
            component: DetailInventoryComponent
          },
          {
            path: 'inventory-basic/:documentId',
            component: DetailInventoryBasicComponent
          },
          {
            path: 'location/:documentId',
            component: DetailLocationComponent
          },
          {
            path: 'vendor/:documentId',
            component: DetailVendorComponent
          },
          {
            path: 'purchase/:documentId',
            component: DetailPurchaseComponent
          },
          {
            path: 'report/:documentId',
            component: DetailReportComponent
          },
          {
            path: 'transaction/:documentId',
            component: DetailTransactionComponent
          },
          {
            path: 'user/:documentId',
            component: DetailUserComponent
          },
          {
            path: 'user-group/:documentId',
            component: DetailUserGroupComponent
          },
          {
            path: 'user-authorization/:documentId',
            component: DetailUserAuthorizationComponent
          },
          {
            path: 'employee/:documentId',
            component: DetailEmployeeComponent
          },
          {
            path: 'form/:documentId',
            component: DetailFormComponent
          },
          {
            path: 'tax/:documentId',
            component: DetailTaxComponent
          },
          {
            path: 'permission/:documentId',
            component: DetailPermissionComponent
          },
          {
            path: 'group/:documentId',
            component: DetailGroupComponent
          },
          {
            path: 'permissionGroup/:documentId',
            component: DetailPermissionGroupComponent
          },
          {
            path: 'return-type/:documentId',
            component: DetailReturnTypeComponent
          },
          {
            path: 'terminal/:documentId',
            component: DetailTerminalComponent
          },
          {
            path: 'vendor-product/:documentId',
            component: DetailVendorInventoryComponent
          },
          {
            path: 'menu-actions/:documentId',
            component: DetailMenuActionComponent
          },
          {
            path: 'discountGroup/:documentId',
            component: DetailDiscountGroupComponent
          },
          {
            path: 'discount-wizard/:documentId',
            component: DiscountWizardComponent
          }
        ]
      }
    ]
  },
  // {
  //   path: 'marketing',
  //   component: MarketingComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: DashboardComponent,
  //       children: [
  //         {
  //           path: 'conversations',
  //           component: ConversationsComponent
  //         },
  //         {
  //           path: 'account/:documentId',
  //           component: DetailAccountComponent
  //         },
  //         {
  //           path: 'batch/:documentId',
  //           component: DetailBatchComponent
  //         },
  //         {
  //           path: 'customer/:documentId',
  //           component: DetailCustomerComponent
  //         },
  //         {
  //           path: 'discount/:documentId',
  //           component: DetailDiscountComponent
  //         },
  //         {
  //           path: 'dynamic/:documentId',
  //           component: DetailDynamicComponent
  //         },
  //         {
  //           path: 'inventory/:documentId',
  //           component: DetailInventoryComponent
  //         },
  //         {
  //           path: 'location/:documentId',
  //           component: DetailLocationComponent
  //         },
  //         {
  //           path: 'purchase/:documentId',
  //           component: DetailPurchaseComponent
  //         },
  //         {
  //           path: 'report/:documentId',
  //           component: DetailReportComponent
  //         },
  //         {
  //           path: 'transaction/:documentId',
  //           component: DetailTransactionComponent
  //         },
  //         {
  //           path: 'user/:documentId',
  //           component: DetailUserComponent
  //         }
  //       ]
  //     },
  //     // {
  //     //   path: 'scheduled',
  //     //   component: ScheduledComponent
  //     // },
  //     // {
  //     //   path: 'recent',
  //     //   component: RecentComponent
  //     // },
  //     {
  //       path: 'general',
  //       children: [
  //         {
  //           path: 'autoresponder',
  //           component: AutoresponderComponent
  //         },
  //         {
  //           path: 'settings',
  //           component: SettingsComponent
  //         }
  //       ]
  //     },
  //     {
  //       path: 'automatic',
  //       children: [
  //         {
  //           path: 'birthday',
  //           component: BirthdayComponent
  //         },
  //         {
  //           path: 'anniversary',
  //           component: AnniversaryComponent
  //         }
  //       ]
  //     },
  //     {
  //       path: 'communication/:documentId',
  //       component: CommunicationComponent,
  //       children: [
  //         {
  //           path: 'conversations',
  //           component: ConversationsComponent
  //         },
  //         {
  //           path: 'account/:documentId',
  //           component: DetailAccountComponent
  //         },
  //         {
  //           path: 'batch/:documentId',
  //           component: DetailBatchComponent
  //         },
  //         {
  //           path: 'customer/:documentId',
  //           component: DetailCustomerComponent
  //         },
  //         {
  //           path: 'discount/:documentId',
  //           component: DetailDiscountComponent
  //         },
  //         {
  //           path: 'dynamic/:documentId',
  //           component: DetailDynamicComponent
  //         },
  //         {
  //           path: 'inventory/:documentId',
  //           component: DetailInventoryComponent
  //         },
  //         {
  //           path: 'location/:documentId',
  //           component: DetailLocationComponent
  //         },
  //         {
  //           path: 'purchase/:documentId',
  //           component: DetailPurchaseComponent
  //         },
  //         {
  //           path: 'report/:documentId',
  //           component: DetailReportComponent
  //         },
  //         {
  //           path: 'transaction/:documentId',
  //           component: DetailTransactionComponent
  //         },
  //         {
  //           path: 'user/:documentId',
  //           component: DetailUserComponent
  //         }
  //       ]
  //     },
  //     {
  //       path: '',
  //       redirectTo: 'dashboard',
  //       pathMatch: 'prefix'
  //     }
  //   ]
  // },
  {
    path: 'virtual-terminal',
    component: VirtualTerminalComponent,
    canActivate: [AuthGuard],
    // children: [
    //   {
    //     path: 'conversations',
    //     component: ConversationsComponent
    //   }
    // ]
  },
  {
    path: 'purchase/:purchaseId',
    component: PurchaseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase',
    component: PurchaseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'templates/:templateId',
    component: TemplateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'transfers/:purchaseId',
    component: TransferComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'transfers',
    component: TransferComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'inventory',
  //   component: InventoryComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'inventory/:purchaseId',
  //   component: InventoryComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'count',
  //   component: CountComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'count/:purchaseId',
  //   component: CountComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'fulfillment',
  //   component: FulfillmentComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'reports',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
