<div class="detail" [class.visible]="group">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <h2 *ngIf="group">{{group.subtype}}</h2>
  </header>
  <section>
    <div *ngIf="!group" class="loading">Loading . . .</div>
    <div *ngIf="group">

      <div class="input-set input-set-general">

        <div>
          <label class="option-header">{{group.label}} Name</label>
          <input type="text" [(ngModel)]="group.name" />
        </div>

        <ng-container *ngIf="group.subtype !== 'buyGroup'">

          <div>
            <label class="option-header">Color</label>
            <div class="color-block" [cpOutputFormat]="'rgba'" [(colorPicker)]="group.background" [style.background]="group.background" (colorPickerChange)="colorChanged($event, group)"></div>
          </div>
  
          <ng-container *ngIf="group.type == 'tax'">
            <div>
              <label class="option-header">Percentage (%)</label>
              <input type="text" [(ngModel)]="group.percentage" />
            </div>
            <div>
              <label class="option-header">Basis</label>
              <select [(ngModel)]="group.basis">
                <option value="item">Item</option>
                <option value="invoice">Invoice</option>
              </select>
            </div>
            <div>
              <label class="option-header">Rounding</label>
              <select [(ngModel)]="group.rounding">
                <option value="roundHalfUp">Round Half Up</option>
                <option value="roundUp">Round Up</option>
              </select>
            </div>
          </ng-container>
  
          <div>
            <label class="option-header">Display Order</label>
            <input type="number" [(ngModel)]="group.displayOrder" />
          </div>
  
          <div class="checkbox">
            <input type="checkbox" [(ngModel)]="group.ise" [ngModelOptions]="{standalone: true}" name="ise" />
            Visible in ISE
          </div>
        </ng-container>

        <!-- <div>
          <label>Key</label>
          <input type="text" [(ngModel)]="group.key" />
        </div> -->


        <div style="padding-bottom: 16px;">
          <label class="option-header">Description</label>
          <textarea class="note-input" [(ngModel)]="group.description"></textarea>
        </div>

        <ng-container *ngIf="group.subtype != 'buyGroup'">
          <app-dropdown [(optionGroup)]="locationDropdown"
              title="Retail Locations"
              placeholder="(Choose Location)"
              [disableSearch]="true"
              (searchChanged)="searchLocations($event)">
          </app-dropdown>
        </ng-container>

        <ng-container *ngIf="group.subtype == 'buyGroup'">
          <app-dropdown [(optionGroup)]="vendorDropdown"
              title="Vendor"
              placeholder="(Choose Vendor)"
              [disableSearch]="false"
              (searchChanged)="searchVendors($event)">
          </app-dropdown>
        </ng-container>


        <div *ngIf="groups && groups.length > 0 && group.subtype == 'category'">
          <label class="option-header">Move Products</label>
          <div class="move-description">Move products from {{group.name}} to</div>
          <select [(ngModel)]="moveToGroupId">
            <option *ngFor="let g of groups" [value]="g._id">{{g.name}}</option>
          </select>
          <div style="height: 80px;">
            <button class="button yellow" (click)="moveItems()">Move</button>
          </div>
          
        </div>



        <div class="errors">
          <div *ngFor="let error of errors" class="error">{{error}}</div>
        </div>

        <div class="buttons">
          <div class="button-full" (click)="viewProducts()">View Products</div>
        </div>

      </div>
      <div class="buttons">
        <button class="button-full green" (click)="saveGroup()">Save</button>
      </div>
      <div class="buttons">
        <button class="button-full red" (click)="deleteGroup()">Delete</button>
      </div>

    </div>
  </section>
</div>
