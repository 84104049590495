<div class="detail" [class.visible]="account">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
    <h2 *ngIf="!account">Inventory Item</h2>
    <h2 *ngIf="account" class="upper">{{account.subtype}}</h2>
  </header>
  <section>
    <div *ngIf="!account" class="loading">Loading . . .</div>
    <div *ngIf="account" class="account">
      <div class="column-general">
        <div class="field">
          <label>Item Name</label>
          <input type="text" [(ngModel)]="account.name" />
        </div>
        <div class="field">
          <label>Short Name</label>
          <input type="text" [(ngModel)]="account.shortName" />
        </div>

        <div *ngIf="account.subtype != 'selector'" class="line-field">
          <div class="label-large">Unit of Measure</div>
          <select [(ngModel)]="account.unitOfMeasure">
            <option value="EA">EA</option>
            <option value="OZ">OZ</option>
            <option value="FOZ">FOZ</option>
            <option value="BX">BX</option>
            <option value="LB">LB</option>
            <option value="ML">ML</option>
            <option value="YD">YD</option>
            <option value="SLICE">SLICE</option>
            <option value="GL">GL</option>
            <option value="GR">GR</option>
            <option value="FT">FT</option>
            <option value="CS">CS</option>
            <option value="QT">QT</option>
            <option value="BT">BT</option>
            <option value="CS">CS</option>
            <option value="CN">CN</option>
            <option value="BG">BG</option>
            <option value="PK">PK</option>
            <option value="RL">RL</option>
            <option value="ST">ST</option>
            <option value="BK">BK</option>
            <option value="USD">USD</option>
          </select>
        </div>

        <div class="field">
          <label>Unit Description</label>
          <input type="text" [(ngModel)]="account.unitDescription" />
        </div>

        <!-- <div class="line-field">
          <div class="label-large">Type</div>
          <select [(ngModel)]="account.subtype" (ngModelChange)="subtypeChanged()">
            <option value="retail">Retail Product</option>
            <option value="vendor">Vendor Product</option>
            <option value="component">Component</option>
            <option value="selector">Selector</option>
          </select>
        </div> -->

        <div *ngIf="account.subtype == 'retail' || account.subtype == 'selector'" class="line-field">
          <div class="label-large">ISE Visible</div>
          <app-toggle (valueChanged)="toggleBoolean('ise')" [checked]="account.ise"></app-toggle>
        </div>

        <div *ngIf="account.ise && (account.subtype == 'retail' || account.subtype == 'selector')" class="line-field">
          <div class="label-large">Sort</div>
          <input type="number" [(ngModel)]="account.sort" />
        </div>

        <div *ngIf="account.subtype != 'component' && account.subtype != 'selector' && account.subtype != 'vendor'" class="line-field">
          <div class="label-large">Price $</div>
          <input type="number" [(ngModel)]="account.price" (ngModelChange)="updateAccountCosts()" />
        </div>

        <ng-container *ngIf="account.subtype == 'retail' || account.subtype == 'component' || account.subtype == 'vendor'">
          
          <div *ngIf="account.directCostAllowed" class="line-field">
            <div class="label-large">Cost $</div>
            <input *ngIf="!account.defaultVendorAccountId" type="number" [(ngModel)]="account.cost" (ngModelChange)="updateAccountCosts()" />
            <div *ngIf="account.defaultVendorAccountId" class="value">
              {{account.cost*100 | rf:'currencyDetail'}}
            </div>
          </div>

          <div *ngIf="!account.directCostAllowed && account.calculatedCost" class="field">
            <div *ngIf="account.calculatedCost.high == account.calculatedCost.low" class="line-field">
              <div class="label-large bold">Calculated Cost</div>
              <div class="value">{{account.calculatedCost.high | rf:'currencyDetail'}}</div>
            </div>


            <div *ngIf="account.calculatedCost.high != account.calculatedCost.low" class="field">
              <div class="label-center">Calculated Cost</div>
              <div class="stacked-line-field">
                <div class="label-large">High</div>
                <div class="value">{{account.calculatedCost.high | rf:'currencyDetail'}}</div>
              </div>
              <div class="stacked-line-field">
                <div class="label-large">Uncovered High</div>
                <div class="value">{{account.calculatedCost.uncoveredHigh | rf:'currencyDetail'}}</div>
              </div>
              <div class="stacked-line-field">
                <div class="label-large">Default</div>
                <div class="value">{{account.calculatedCost.default | rf:'currencyDetail'}}</div>
              </div>
              <div class="stacked-line-field">
                <div class="label-large">Low</div>
                <div class="value">{{account.calculatedCost.low | rf:'currencyDetail'}}</div>
              </div>
            </div>
          </div>

          <div *ngIf="account.margin" class="line-field">
            <div class="label-large">Margin</div>
            <div class="value">{{account.margin | rf:'currencyDetail'}}</div>
          </div>

          <div *ngIf="account.marginPercent" class="line-field">
            <div class="label-large">% Margin</div>
            <div class="value">{{account.marginPercent | rf:'percent'}}%</div>
          </div>
        </ng-container>

        <div *ngIf="account.applicableDiscounts && account.applicableDiscounts.length > 0" class="field">
          <label>Discounts</label>
          <div *ngFor="let discount of account.applicableDiscounts" (click)="viewDiscountAccount(discount._id)" class="vendor-link">
            <div class="icon-button">
              <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
            </div>
            {{discount.name}}
          </div>
        </div>




        <div class="line-field">
          <div class="label-large">SKU</div>
          <input type="text" [(ngModel)]="account.sku" />
        </div>
        <div class="field">
          <label>Barcode</label>
          <div *ngFor="let barcode of account.barcode; let i = index" class="line-field">
            <div class="remove" (click)="removeBarcode(i)">X</div>
            <input type="text" [(ngModel)]="barcode.value" />
          </div>
          <div style="text-align: right; padding-bottom: 8px;">
            <div style="display: inline-block; font-size: 20px; font-weight: 700; padding: 0 16px;" (click)="addBarcode()">+</div>
          </div>
        </div>
        <!-- <div class="line-field">
          <div class="label-large">Barcode / UPC</div>
          <input type="text" [(ngModel)]="account.barcode" />
          <div class="small-add">+</div>
        </div> -->
        <div class="line-field">
          <div class="label-large">Remote ID</div>
          <input type="text" [(ngModel)]="account.remoteId" />
        </div>
        <div *ngIf="account.subtype != 'vendor'" class="line-field">
          <div class="label-large">Printer</div>
          <select [(ngModel)]="account.printer">
            <option [value]="undefined">None</option>
            <option value="kitchen">Kitchen</option>
            <option value="bar">Bar</option>
          </select>
        </div>

        <div *ngIf="account.subtype == 'retail' || account.subtype == 'component'" class="line-field">
          <div class="label-large">Inventory Count</div>
          <app-toggle (valueChanged)="toggleBoolean('inventoryCountable')" [checked]="account.inventoryCountable"></app-toggle>
        </div>

        <div *ngIf="account.subtype == 'retail'" class="line-field">
          <div class="label-large">Variable Price</div>
          <app-toggle (valueChanged)="toggleBoolean('priceIsVariable')" [checked]="account.priceIsVariable"></app-toggle>
        </div>

        <div *ngIf="account.subtype == 'retail'" class="line-field">
          <div class="label-large">Is Gift Card</div>
          <app-toggle (valueChanged)="toggleBoolean('isGiftCard')" [checked]="account.isGiftCard"></app-toggle>
        </div>

        <div class="field">
          <label>Image</label>
          <div *ngIf="account.imgPreview" class="img-preview">
            <img [src]="account.imgPreview" />
          </div>
          <input type="text" [(ngModel)]="account.imgUrl" />

          <div *ngIf="image && image.uploaderUrl">
            <iframe id="rffile" title="RF File Upload" [src]="image.uploaderUrl" frameborder="0" height="80px" width="100%"></iframe>
          </div>

        </div>
        <div class="field">
          <label>Description</label>
          <textarea class="note-input" [(ngModel)]="account.description"></textarea>
        </div>


        <ng-container *ngIf="account.customFields && account.customFields.length > 0">
          <div *ngFor="let customField of account.customFields" class="field">
            <label>{{customField.name}}</label>
            <input *ngIf="customField.type == 'number'" type="number" [(ngModel)]="account[customField.key]" />
          </div>
        </ng-container>






        <div *ngFor="let optionGroup of optionFields; let i = index" class="field"
              [class.hidden]="optionGroup.key === 'taxId' && !showTaxControl">
          <app-dropdown *ngIf="!(optionGroup.key === 'taxId' && !showTaxControl)"
              [optionGroup]="optionFields[i]"
              (searchChanged)="search(optionGroup, $event)">
          </app-dropdown>
        </div>



        <div *ngIf="account.vendorProducts && account.subtype != 'selector'" class="field">
          <label *ngIf="!account.options || account.options.length == 0">Vendor Products</label>

          <ng-container *ngIf="account.vendorProducts.length > 0">
            <div *ngFor="let vendorProduct of account.vendorProducts" (click)="viewVendorAccount(vendorProduct._id)" class="vendor-link">
              <div class="icon-button">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
              </div>
              {{vendorProduct.locationName}}: {{vendorProduct.name}} {{vendorProduct.unitDescription}}
            </div>
          </ng-container>

          <ng-container *ngIf="account.vendorProducts.length == 0 && (!account.options || account.options.length == 0)">
            <div class="vendor-link">
              <div class="button yellow" (click)="createVendorProduct()">Create</div>
              No Vendor Products
            </div>
          </ng-container>

        </div>

        <div class="buttons">
          <button *ngIf="reportAuthorizations.dependentItems" class="button" (click)="viewDependentItems()">View Parent Items</button>

          <button *ngIf="reportAuthorizations.descendantItems && account.options && account.options.length > 0 && account.options[0].options && account.options[0].options.length > 0" class="button" (click)="viewDescendantItems()">View Child Items</button>

          <button class="button" (click)="viewTransferHistory()">View Transfer History</button>

          <div *ngFor="let view of account.views" class="button options-toggle" [class.green]="account.activeViews == view.key" (click)="toggleDetailView(view.key)">
            <ng-container *ngIf="account.activeView == view.key">Hide</ng-container>
            <ng-container *ngIf="account.activeView != view.key">View</ng-container>
            {{view.name}}
          </div>
          <div class="button red" (click)="deleteAccount()">Delete Item</div>
          <div *ngIf="account.deleteError">
            <div class="error">{{account.deleteError.msg}}</div>
            <div *ngIf="account.deleteError.dependentAccounts">
              <div *ngFor="let dependentAccount of account.deleteError.dependentAccounts" class="dependent-account" (click)="viewDependentAccount(dependentAccount._id)">{{dependentAccount.name}}</div>
            </div>
          </div>

          <div class="button yellow" (click)="cloneAccount()">Clone Item</div>
          <div class="button green" (click)="save()">Save Item</div>
          <div *ngFor="let error of account.errors" class="error">{{error}}</div>
        </div>
      </div>

      <div *ngIf="account.activeView == 'dependents'" class="column-chart">
        <div class="column-header">Dependents</div>
        <div>A list of all items which require this item.</div>
      </div>

      <div *ngIf="account.activeView =='audit'" class="column-audit">
        <app-audit collectionName="Account" [documentId]="documentId"></app-audit>
      </div>

      <div *ngIf="account.activeView == 'descendants'" class="column-chart">
        <div class="column-header">Descendants</div>
        <div>A tree view of all items included or potentially included in this item along with their respective quantities and costs.</div>
      </div>

      <div *ngIf="account.activeView == 'configuration'" class="column-option-groups">
        <div class="column-header">Option Groups</div>
        <ng-container *ngIf="account.options">
          <div *ngFor="let optionGroup of account.options; let optionIndex = index;" class="option-group" [class.active]="account.activeOptionGroup && optionGroup == account.activeOptionGroup" (click)="setActiveOptionGroup(optionGroup)">
            <div class="field">
              <label>Group Type</label>
              <select [(ngModel)]="optionGroup.type">
                <option *ngIf="account.subtype != 'selector'" value="components">Components</option>
                <option value="select">Select One</option>
                <option *ngIf="account.subtype != 'selector'" value="multiple">Select Multiple</option>
                <option *ngIf="account.subtype != 'selector'" value="multipleMultiple">Multiple Multiple</option>
              </select>
            </div>
            <div *ngIf="optionGroup.type != 'components'" class="field">
              <label>Name</label>
              <input type="text" [(ngModel)]="optionGroup.name" />
            </div>
            <div *ngIf="optionGroup.type == 'multiple' || optionGroup.type == 'multipleMultiple'" class="field">
              <label>Minimum</label>
              <input type="number" [(ngModel)]="optionGroup.min" (ngModelChange)="updateOptionGroupCosts(optionGroup)" />
            </div>
            <div *ngIf="optionGroup.type == 'multiple' || optionGroup.type == 'multipleMultiple'" class="field">
              <label>Maximum</label>
              <input type="number" [(ngModel)]="optionGroup.max" (ngModelChange)="updateOptionGroupCosts(optionGroup)" />
            </div>

            <ng-container *ngIf="account.subtype == 'retail' || account.subtype == 'component'">
              <div *ngIf="!optionGroup.calculatedCost" class="line-fields">
                <div class="label-large bold">Calculated Cost</div>
                <div class="value">{{optionGroup.cost | rf:'currencyDetail'}}</div>
              </div>

              <div *ngIf="optionGroup.calculatedCost &&optionGroup.calculatedCost.high == optionGroup.calculatedCost.low" class="line-field">
                <div class="label-large bold">Calculated Cost</div>
                <div class="value">{{optionGroup.calculatedCost.high | rf:'currencyDetail'}}</div>
              </div>

              <div *ngIf="optionGroup.calculatedCost &&optionGroup.calculatedCost.high != optionGroup.calculatedCost.low" class="field">
                <div class="label-center">Calculated Cost</div>
                <div class="stacked-line-field">
                  <div class="label-large">High</div>
                  <div class="value">{{optionGroup.calculatedCost.high | rf:'currencyDetail'}}</div>
                </div>
                <div class="stacked-line-field">
                  <div class="label-large">Uncovered High</div>
                  <div class="value">{{optionGroup.calculatedCost.uncoveredHigh | rf:'currencyDetail'}}</div>
                </div>
                <div class="stacked-line-field">
                  <div class="label-large">Default</div>
                  <div class="value">{{optionGroup.calculatedCost.default | rf:'currencyDetail'}}</div>
                </div>
                <div class="stacked-line-field">
                  <div class="label-large">Low</div>
                  <div class="value">{{optionGroup.calculatedCost.low | rf:'currencyDetail'}}</div>
                </div>
              </div>
            </ng-container>

            <div class="icon-buttons">
              <div class="icon-button red" (click)="removeOptionGroup(optionIndex)">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 736v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm128 724v-948h-896v948q0 22 7 40.5t14.5 27 10.5 8.5h832q3 0 10.5-8.5t14.5-27 7-40.5zm-672-1076h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>
              </div>
              <div class="icon-button" [class.hidden]="account.activeOptionGroup && optionGroup == account.activeOptionGroup">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
              </div>
            </div>

          </div>
        </ng-container>
        <div class="buttons">
          <div *ngIf="account.subtype != 'selector' || !account.options || account.options.length == 0" class="button green" (click)="addOptionGroup()">Add Option Group</div>
        </div>
      </div>

      <div *ngIf="account.activeView == 'configuration' && account.activeOptionGroup" class="column-options">
        <div class="column-header">
          <ng-container *ngIf="account.activeOptionGroup.type == 'components'">Components</ng-container>
          <ng-container *ngIf="account.activeOptionGroup.type != 'components'">{{account.activeOptionGroup.name}}</ng-container>
        </div>
        <ng-container *ngIf="account.activeOptionGroup.options">
          <div *ngFor="let option of account.activeOptionGroup.options; let optionIndex = index;" class="group-option">
            <div class="account-type">{{account.accounts[option.accountId].subtype}}</div>
            <div class="account-name">{{account.accounts[option.accountId].name}}</div>
            <div class="account-type">{{account.accounts[option.accountId].unitDescription}}</div>

            <div *ngIf="account.activeOptionGroup.type != 'components'" class="field">
              <label>Display Name</label>
              <input type="text" [(ngModel)]="option.name" />
            </div>

            <div *ngIf="account.activeOptionGroup.type != 'components' && account.subtype != 'selector'" class="line-field" [class.warning]="option.price && option.price < account.accounts[option.accountId].cost*option.qty/100">
              <div class="label-large">Option Price $</div>
              <input type="number" [(ngModel)]="option.price" (ngModelChange)="optionPriceUpdated()" />
            </div>

            <div class="line-field">
              <div class="label-large">Qty</div>
              <input type="number" [(ngModel)]="option.qty" (ngModelChange)="optionQtyUpdated(option)" />
              <div>{{account.accounts[option.accountId].unitOfMeasure}}</div>
            </div>

            <div *ngIf="account.activeOptionGroup.type != 'components' && account.subtype != 'selector'" class="line-field">
              <div class="label-large">Default</div>
              <app-toggle *ngIf="account.activeOptionGroup.type != 'multipleMultiple'" 
                          (valueChanged)="optionToggleDefault(option)"
                          [checked]="option.default">
              </app-toggle>
              <input *ngIf="account.activeOptionGroup.type == 'multipleMultiple'" type="number" [(ngModel)]="option.default" />
            </div>

            <ng-container *ngIf="account.subtype == 'retail' || account.subtype == 'component'">
              <div *ngIf="accounts[option.accountId].cost">
                <div class="label-center">Calculated Cost</div>
                <div class="line-value">{{option.qty}} &#64; {{account.accounts[option.accountId].cost | rf:'currencyDetail'}} = {{account.accounts[option.accountId].cost*option.qty | rf:'currencyDetail'}}</div>
              </div>

              <ng-container *ngIf="!accounts[option.accountId].cost && accounts[option.accountId].calculatedCost">
                <div *ngIf="accounts[option.accountId].calculatedCost.high == accounts[option.accountId].calculatedCost.low">
                  <div class="label-center">Calculated Cost</div>
                  <div class="line-value">{{option.qty}} &#64; {{accounts[option.accountId].calculatedCost.high | rf:'currencyDetail'}} = {{accounts[option.accountId].calculatedCost.high*option.qty | rf:'currencyDetail'}}</div>
                </div>
                <div *ngIf="accounts[option.accountId].calculatedCost.high != accounts[option.accountId].calculatedCost.low">
                  <div class="label-center">Calculated Cost</div>
                  <div class="stacked-line-field">
                    <div class="label-large">High</div>
                    <div class="value">{{accounts[option.accountId].calculatedCost.high*option.qty | rf:'currencyDetail'}}</div>
                  </div>
                  <div class="stacked-line-field">
                    <div class="label-large">Uncovered High</div>
                    <div class="value">{{accounts[option.accountId].calculatedCost.uncoveredHigh*option.qty | rf:'currencyDetail'}}</div>
                  </div>
                  <div class="stacked-line-field">
                    <div class="label-large">Default</div>
                    <div class="value">{{accounts[option.accountId].calculatedCost.default*option.qty | rf:'currencyDetail'}}</div>
                  </div>
                  <div class="stacked-line-field">
                    <div class="label-large">Low</div>
                    <div class="value">{{accounts[option.accountId].calculatedCost.low*option.qty | rf:'currencyDetail'}}</div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div class="icon-buttons">
              <div class="icon-button red" (click)="removeOption(optionIndex)">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 736v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm128 724v-948h-896v948q0 22 7 40.5t14.5 27 10.5 8.5h832q3 0 10.5-8.5t14.5-27 7-40.5zm-672-1076h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>
              </div>
              <div class="icon-button" (click)="viewOption(option.accountId)">
                <svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="field">
          <app-dropdown [(optionGroup)]="accountOptionField"
            placeholder="Find / Add"
            [enableAddNew]="true"
            (optionGroupChange)="updateAccountOptions()"
            (searchChanged)="searchAccounts($event)"
            (newSelected)="addNewAccount($event)">
          </app-dropdown>
        </div>
        <div class="column-spacer"></div>
      </div>
    </div>
  </section>
</div>
