<app-header class="no-print"></app-header>
<ng-container>

  <div class="transfers">

    <div *ngIf="!purchase._id && !purchaseId && purchaseTypeOptions && purchaseTypeOptions.length > 0" class="settings">

      <div class="setting">
        <div class="label">Transfer Name</div>
        <div class="value"><input type="text" [(ngModel)]="purchase.name" /></div>
      </div>

      <div *ngIf="purchase.note" class="setting">
        <div class="label">Notes</div>
        <div class="value"><textarea [(ngModel)]="purchase.note[0]"></textarea></div>
      </div>

      <div class="setting">
        <div class="label">Transfer Type</div>
        <div class="value">
          <select [ngModel]="purchase.type" (ngModelChange)="purchaseTypeChange($event)">
            <option *ngFor="let purchaseType of purchaseTypeOptions" [value]="purchaseType.key">{{purchaseType.name}}</option>
          </select>
        </div>
      </div>

      <ng-container *ngIf="purchase.typeObject">
        <div *ngIf="purchase.typeObject.sourceType" class="setting">
          <div class="label">{{purchase.typeObject.sourceLabel}}</div>
          <div class="value">
            <select [ngModel]="purchase.locationId" (ngModelChange)="purchaseLocationChange($event)">
              <option *ngFor="let location of purchase.locationOptions" [value]="location._id">{{location.name}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="purchase.typeObject.targetType" class="setting">
          <div class="label">{{purchase.typeObject.targetLabel}}</div>
          <div class="value">
            <select [ngModel]="purchase.customerId" (ngModelChange)="purchaseCustomerChange($event)">
              <option *ngFor="let location of purchase.customerOptions" [value]="location._id">{{location.name}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="purchase.typeObject.subtypes" class="setting">
          <div class="label">Subtype</div>
          <div class="value">
            <select [ngModel]="purchase.subtype" (ngModelChange)="purchaseSubtypeChange($event)">
              <option *ngFor="let subtype of purchase.typeObject.subtypes" [value]="subtype.key">{{subtype.name}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="purchase.type == 'adjustment-retail'" class="setting">
          <div class="label">Inventory Count Date/Time</div>
          <div class="value">
            <input type="text"
              ngxDaterangepickerMd
              [(ngModel)]="purchase.countDateTime"
              [timePicker]="true"
              [singleDatePicker]="true" />
          </div>
        </div>

        <div *ngIf="purchase.type == 'vendor-retail'" class="setting">
          <div class="label">Transfer Date</div>
          <div *ngIf="purchase.status == 'open'">
            <input type="text"
              ngxDaterangepickerMd
              [(ngModel)]="purchase.effectiveDate"
              [singleDatePicker]="true" />
          </div>
          <div *ngIf="purchase.status != 'open' && purchase.effectiveDate">
            {{purchase.effectiveDate.startDate | rf:'dateTime'}}
          </div>
        </div>

        <div *ngIf="(purchase.locationId || !purchase.typeObject.sourceType) && (purchase.customerId || !purchase.typeObject.targetType) && (purchase.locationId != purchase.customerId || (!purchase.typeObject.sourceType && !purchase.typeObject.targetType))" class="setting">
          <div class="button-wrapper">
            <div class="button green" (click)="newPurchase()">Build Transfer</div>
          </div>
        </div>

        <div class="mobile-spacer"></div>

      </ng-container>
    </div>

    <ng-container *ngIf="purchase._id">
      <div class="transfers-header">
        <div class="headers" [class]="'status-'+purchase.status">
          <div class="clickable" (click)="startEditSettings()">
            <div class="key">{{purchase.key}}</div>
            <div class="name">{{purchase.name}}</div>
            <div class="notes">{{purchase.notes}}</div>
          </div>
          <div class="purchase-detail">
            <div>
              <div class="label">Transfer Type</div>
              <div class="value">{{purchase.typeObject.name}}</div>
            </div>
            <div *ngIf="purchase.typeObject.sourceType">
              <div class="label">Source</div>
              <div class="value">{{purchase.locationName}}</div>
            </div>
            <div *ngIf="purchase.typeObject.targetType">
              <div class="label">Target</div>
              <div class="value">{{purchase.customerName}}</div>
            </div>
            <div *ngIf="purchase.typeObject.subtypes && purchase.subtype">
              <div class="label">Subtype</div>
              <div class="value">{{purchase.subtype}}</div>
            </div>
            <div *ngIf="purchase.type == 'adjustment-retail'">
              <div class="label">Inventory Count Date/Time</div>
              <div class="value">
                <ng-container *ngIf="purchase.countDateTime">{{purchase.countDateTime | rf:'dateTime'}}</ng-container>
                <ng-container *ngIf="!purchase.countDateTime">{{purchase.timeAdded | rf:'dateTime'}}</ng-container>
              </div>
            </div>
            <div *ngIf="purchase.type == 'vendor-retail'">
              <div class="label">Transfer Date</div>
              <div *ngIf="purchase.status == 'open'" class="value">
                <input type="text"
                  ngxDaterangepickerMd
                  [(ngModel)]="purchase.effectiveDate"
                  [singleDatePicker]="true" />
              </div>
              <div *ngIf="purchase.status != 'open' && purchase.effectiveDate" class="value">
                {{purchase.effectiveDate.startDate | rf:'dateTime'}}
              </div>
            </div>
          </div>
          <div class="purchase-summary">
            <div *ngIf="purchase.typeObject.sourceType">Source: {{purchase.locationName}}</div>
            <div *ngIf="purchase.typeObject.targetType">Target: {{purchase.customerName}}</div>
          </div>
          <div *ngIf="purchase.exportOptions" class="export no-print">
            <ng-container *ngFor="let exportOption of purchase.exportOptions">
              <div class="download-button" (click)="downloadPurchase(exportOption.key)">
                <svg width="100%" height="100%" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><path d="M19.416,9.032c-0.318,0 -0.572,0.255 -0.572,0.573l0,5.209c0,1.417 -1.155,2.567 -2.567,2.567l-12.565,0c-1.417,0 -2.567,-1.154 -2.567,-2.567l0,-5.294c0,-0.318 -0.254,-0.572 -0.573,-0.572c-0.317,0 -0.572,0.254 -0.572,0.572l0,5.294c0,2.049 1.667,3.712 3.712,3.712l12.565,0c2.049,0 3.712,-1.667 3.712,-3.712l0,-5.209c0,-0.314 -0.255,-0.573 -0.573,-0.573" style="fill-rule:nonzero;"/><path d="M9.592,13.978c0.11,0.11 0.259,0.17 0.402,0.17c0.145,0 0.293,-0.055 0.404,-0.17l3.639,-3.64c0.225,-0.224 0.225,-0.585 0,-0.809c-0.224,-0.225 -0.585,-0.225 -0.809,0l-2.66,2.664l0,-11.619c0,-0.319 -0.255,-0.574 -0.574,-0.574c-0.317,0 -0.572,0.255 -0.572,0.574l0,11.619l-2.664,-2.664c-0.225,-0.225 -0.585,-0.225 -0.811,0c-0.224,0.224 -0.224,0.585 0,0.809l3.645,3.64Z" style="fill-rule:nonzero;"/></svg>
                {{exportOption.name}}
              </div>
              <ng-container *ngIf="exportOption.templateKeys">
                <div *ngFor="let templateKey of exportOption.templateKeys" class="print-options" (click)="printReport(exportOption.key, templateKey)">
                  <svg width="100%" height="100%" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M448 1536h896v-256h-896v256zm0-640h896v-384h-160q-40 0-68-28t-28-68v-160h-640v640zm1152 64q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128 0v416q0 13-9.5 22.5t-22.5 9.5h-224v160q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-160h-224q-13 0-22.5-9.5t-9.5-22.5v-416q0-79 56.5-135.5t135.5-56.5h64v-544q0-40 28-68t68-28h672q40 0 88 20t76 48l152 152q28 28 48 76t20 88v256h64q79 0 135.5 56.5t56.5 135.5z"/></svg>
                  {{exportOption.name}}
                </div>


                <!-- <div *ngFor="let printTemplate of templateOptions.print" class="print-options" (click)="printReport(printTemplate)">
                  <div class="icon"></div>
                  <label for="print">{{printTemplate.name}}</label>
                </div> -->


              </ng-container>
            </ng-container>
            <!-- <div *ngFor="let exportOption of purchase.exportOptions" class="download-button" (click)="downloadPurchase(exportOption.key)">
              <svg width="100%" height="100%" viewBox="0 0 20 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><path d="M19.416,9.032c-0.318,0 -0.572,0.255 -0.572,0.573l0,5.209c0,1.417 -1.155,2.567 -2.567,2.567l-12.565,0c-1.417,0 -2.567,-1.154 -2.567,-2.567l0,-5.294c0,-0.318 -0.254,-0.572 -0.573,-0.572c-0.317,0 -0.572,0.254 -0.572,0.572l0,5.294c0,2.049 1.667,3.712 3.712,3.712l12.565,0c2.049,0 3.712,-1.667 3.712,-3.712l0,-5.209c0,-0.314 -0.255,-0.573 -0.573,-0.573" style="fill-rule:nonzero;"/><path d="M9.592,13.978c0.11,0.11 0.259,0.17 0.402,0.17c0.145,0 0.293,-0.055 0.404,-0.17l3.639,-3.64c0.225,-0.224 0.225,-0.585 0,-0.809c-0.224,-0.225 -0.585,-0.225 -0.809,0l-2.66,2.664l0,-11.619c0,-0.319 -0.255,-0.574 -0.574,-0.574c-0.317,0 -0.572,0.255 -0.572,0.574l0,11.619l-2.664,-2.664c-0.225,-0.225 -0.585,-0.225 -0.811,0c-0.224,0.224 -0.224,0.585 0,0.809l3.645,3.64Z" style="fill-rule:nonzero;"/></svg>
              {{exportOption.name}}
            </div> -->
          </div>
        </div>
      </div>


      <div *ngIf="purchase.note && purchase.note[0] && purchase.note[0].length > 0" class="purchase-note" (click)="startEditSettings()" [innerHTML]="purchase.note[0] | rf:'nl2br'"></div>
  
  
      <ng-container *ngIf="purchase.status == 'open' || purchase.status == 'pending'">
        <div class="search">
          <input type="text" placeholder="SEARCH" [(ngModel)]="purchase.searchText" (ngModelChange)="searchInventory()" (keydown)="searchKeyDown($event)" />
          <svg *ngIf="purchase.searching" class="processing" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1639 1056q0 5-1 7-64 268-268 434.5t-478 166.5q-146 0-282.5-55t-243.5-157l-129 129q-19 19-45 19t-45-19-19-45v-448q0-26 19-45t45-19h448q26 0 45 19t19 45-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117 8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45 19-45l138-138q-148-137-349-137-134 0-250 65t-186 179q-11 17-53 117-8 23-30 23h-199q-13 0-22.5-9.5t-9.5-22.5v-7q65-268 270-434.5t480-166.5q146 0 284 55.5t245 156.5l130-129q19-19 45-19t45 19 19 45z"/></svg>
          <svg *ngIf="purchase.searchText && !purchase.searching" class="clear" (click)="clearSearch()" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"/></svg>
        </div>
        <div *ngIf="purchase.searchText" class="search-results">
          <div *ngIf="purchase.searchResults && purchase.searchResults.length == 0" class="no-results">No Results</div>
          <ng-container *ngIf="purchase.searchResults && purchase.searchResults.length > 0">
            <div *ngFor="let searchResult of purchase.searchResults; let resultIndex=index;" class="search-result" (click)="selectSearchResult(searchResult)">
              <div class="sku">{{searchResult.sku}}</div>
              <div class="name">{{searchResult.name}}</div>
              <div class="uom">{{searchResult.unitOfMeasure}}</div>
              <div class="unit-description">{{searchResult.unitDescription}}</div>
              <div class="subtype">{{searchResult.subtype}}</div>
            </div>
          </ng-container>
        </div>
      </ng-container>
  










      <!-- Purchase Table Start -->
      <div class="items-header-print print-only">
        <div class="print-sku">SKU</div>
        <div class="print-name">Item</div>
        <ng-container *ngFor="let field of purchase.typeObject.fields[purchase.status]">
          <div [ngClass]="field.class" >{{field.label}}</div>
        </ng-container>
      </div>

      <div class="inventory-table print-only">
        <div *ngFor="let item of purchase.items; let itemIndex = index;" class="row">
          <div class="print-sku">{{item.sku}}</div>
          <div class="print-name">{{item.name}} ({{item.unitOfMeasure}}) {{item.unitDescription}}</div>
          <ng-container *ngFor="let field of purchase.typeObject.fields[purchase.status]">
            <div *ngIf="!purchase.isMobile || field.displayMobile" [ngClass]="field.class">
              {{item[field.key] | rf:field.pipe}}
            </div>
          </ng-container>
        </div>
      </div>
  



      <div class="items-header">
        <div *ngIf="purchase.status == 'open'" class="item-remove no-print">&nbsp;</div>
        <div class="sku-name">SKU/Item</div>
        <ng-container *ngFor="let field of purchase.typeObject.fields[purchase.status]">
          <div [ngClass]="field.class" >{{field.label}}</div>
        </ng-container>
        <div class="scrollbar-spacer"></div>
      </div>

      <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="500" maxBufferPx="500" class="inventory-table no-print" id="inventory-table">
        <ng-container *cdkVirtualFor="let item of purchase.items; let itemIndex = index;">
            <div class="row" [class.even]="itemIndex % 2 == 0" [class.counted]="item.counted" [class.high]="item.qty > item.expectedQty && purchase.type == 'retail-retail'" [class.low]="item.qty < item.expectedQty && purchase.type == 'retail-retail'" id="item-{{itemIndex}}" (click)="setActiveItem(itemIndex)">
                <div *ngIf="purchase.status == 'open'" class="item-remove no-print" (click)="removePurchaseItem(itemIndex)">
                    <svg *ngIf="!item.saving" class="remove" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>
                    <svg *ngIf="item.saving" class="processing" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1639 1056q0 5-1 7-64 268-268 434.5t-478 166.5q-146 0-282.5-55t-243.5-157l-129 129q-19 19-45 19t-45-19-19-45v-448q0-26 19-45t45-19h448q26 0 45 19t19 45-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117 8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45 19-45l138-138q-148-137-349-137-134 0-250 65t-186 179q-11 17-53 117-8 23-30 23h-199q-13 0-22.5-9.5t-9.5-22.5v-7q65-268 270-434.5t480-166.5q146 0 284 55.5t245 156.5l130-129q19-19 45-19t45 19 19 45z"/></svg>
                </div>
                <div class="sku-name">
                    <div class="sku">{{item.sku}}</div>
                    <div class="name">{{item.name}}</div>
                    <div class="units">{{item.unitOfMeasure}} {{item.unitDescription}}</div>
                </div>

                <ng-container *ngFor="let field of purchase.typeObject.fields[purchase.status]">
                  <div *ngIf="!purchase.isMobile || field.displayMobile" [ngClass]="field.class" (click)="fieldClick(field, item)">
                    <ng-container *ngIf="!field.editable || item != activeItem || purchase.isMobile">
                      {{item[field.key] | rf:field.pipe}}
                    </ng-container>
                    <ng-container *ngIf="field.editable && item == activeItem && !purchase.isMobile">
                      <input type="number" id="item-{{itemIndex}}-{{field.key}}" [(ngModel)]="item[field.key]" (focus)="setInputTargetField(field.key)" (ngModelChange)="qtyChange($event)" (blur)="confirmChange(item)" (keydown.Tab)="fieldTab($event);false" />
                    </ng-container>
                  </div>
                </ng-container>

            </div>
        </ng-container>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
        <div class="virtual-spacer"></div>
      </cdk-virtual-scroll-viewport>

      <div class="items-footer">
        
        <div *ngIf="purchase.status == 'pending' && purchase.authorization.complete && !purchase.isMobile" class="button yellow" (click)="updateTransferStatus('open')">Edit</div>

        <ng-container *ngIf="purchase.typeObject.allowAddAll && purchase.status == 'open' && !purchase.isMobile">
          <div class="button green" (click)="addAll()">Add All</div>
          <div *ngIf="availableCategories && availableCategories.length > 0" class="button-modifier">
            <select [(ngModel)]="purchase.selectedCategory">
              <option *ngFor="let category of availableCategories" [ngValue]="category">from {{category.subtype}} {{category.name}}</option>
            </select>
          </div>
        </ng-container>
        <div *ngIf="purchase.items.length > 0 && purchase.status == 'open' && !purchase.isMobile && purchase.authorization.update" class="button red" (click)="removeAll()">Remove All</div>
        <div *ngIf="purchase.items.length == 0 && purchase.status == 'open' && !purchase.isMobile && purchase.authorization.update" class="button red" (click)="delete()">Delete Transfer</div>

        <ng-container *ngIf="purchase.status == 'open' && !purchase.isMobile && purchase.authorization.upload && purchase.authorization.update">
          <input #purchasecsv class="invisible" type="file" name="purchasecsv" (change)="onCsvFileChange($event)" />
          <div *ngIf="purchase.items.length == 0 && !purchase.importing" class="button yellow import" (click)="importPurchase()">Import .csv</div>
        </ng-container>


        <div class="stretcher"></div>
        <div class="purchase-stat">
          <div class="label">Lines:</div>
          <div class="value">{{purchase.items.length | rf:'number'}}</div>
        </div>
        <div class="purchase-stat">
          <div class="label">Qty:</div>
          <div class="value">{{purchase.qtyTotal | rf:'number'}}</div>
        </div>
        <div *ngIf="!purchase.typeObject.hideCostTotal" class="purchase-stat">
          <div class="label">Total:</div>
          <div class="value">{{purchase.costTotal | rf:'currency'}}</div>
        </div>

        <div *ngIf="(purchase.status == 'open' || purchase.status == 'pending') && purchase.authorization.update" class="button green" (click)="saveButton()">Save</div>
        <div *ngIf="purchase.status == 'open' && purchase.authorization.update && purchase.typeObject.fields.pending && purchase.typeObject.fields.pending.length > 0" class="button green" (click)="startEditStatus('pending')">Send</div>
        <div *ngIf="purchase.status == 'pending' && purchase.authorization.complete && purchase.uncounted > 0 && !purchase.isMobile && purchase.typeObject.requirePendingCount" class="button yellow" (click)="markAllCounted()">All Counted</div>
        <div *ngIf="purchase.status == 'pending' && purchase.authorization.complete && !purchase.isMobile && !purchase.typeObject.defaultPendingQty" class="button yellow" (click)="markAllReceived()">All Received</div>
        <div *ngIf="purchase.status == 'pending' && purchase.authorization.complete && (!purchase.typeObject.requirePendingCount || purchase.uncounted == 0 || !purchase.isMobile)" class="button green" (click)="complete()">Complete</div>
        <div *ngIf="purchase.status == 'processing' && purchase.authorization.retry" class="button yellow" (click)="retry()">Retry</div>

      </div>
      <div class="status">
        <div class="purchase-status">{{purchase.status}}</div>
        <div class="app-version">v{{version}}</div>
        <div class="saved-status">Saved: {{purchase.timeUpdated | rf:'dateTime'}}</div>
      </div>
      <!-- Purchase Table End -->








      <div *ngIf="activeItem" class="active-item no-print">
        <div class="window">
          <div *ngIf="false" class="active-item-detail">Set Value / Add to Value  |  Unit Options</div>
        </div>
        <div class="body">
            <div class="count">
              <ng-container *ngFor="let field of purchase.typeObject.fields[purchase.status]">
                <div *ngIf="field.displayMobile || field.editable" [class.active]="purchase.inputTargetField == field.key" (click)="setInputTargetField(field.key, field.editable)">
                  <div class="label">{{field.label}}</div>
                  <div class="value">

                    

                    <div *ngIf="field.key == 'targetQtyCalculated' && (activeItem.previousCount !== undefined)" class="previous-count">{{activeItem.previousCount | rf:number}} + </div>
                    {{activeItem[field.key] | rf:field.pipe}}



                  </div>
                </div>
              </ng-container>
            </div>
    
            <div class="num-pad">
                <div class="ripple-btn" (click)="screenBtnPress(1)">1</div>
                <div class="ripple-btn" (click)="screenBtnPress(2)">2</div>
                <div class="ripple-btn" (click)="screenBtnPress(3)">3</div>
                <div class="ripple-btn" (click)="screenBtnPress(4)">4</div>
                <div class="ripple-btn" (click)="screenBtnPress(5)">5</div>
                <div class="ripple-btn" (click)="screenBtnPress(6)">6</div>
                <div class="ripple-btn" (click)="screenBtnPress(7)">7</div>
                <div class="ripple-btn" (click)="screenBtnPress(8)">8</div>
                <div class="ripple-btn" (click)="screenBtnPress(9)">9</div>
                <div class="ripple-btn" (click)="screenBtnPress('c')">C</div>
                <div class="ripple-btn" (click)="screenBtnPress(0)">0</div>
                <div class="ripple-btn" (click)="screenBtnPress('d')">&lt;</div>
            </div>
    
        </div>
        <div class="buttons">
            <div *ngIf="purchase.status == 'open'" class="red" (click)="removePurchaseItem(activeItem.index)">Remove<br/>Item</div>
            <div class="yellow" (click)="cancel()">Discard<br/>Change</div>
            <div class="green" (click)="saveItem()">Save<br/>Change</div>
        </div>
      </div>
    </ng-container>

  </div>




  <!-- SELECT ADD TO COUNT -->
  <div *ngIf="purchase.modeSelect" class="modal-wrapper" (mousedown)="setMode('cancel')">
    <div class="modal-contents" (mousedown)="stopPropagation($event)">
      <div class="modal-header">Item Counted</div>
      <div class="modal-body">

        <div class="msg">
          This item has already been counted. <br/>Would you like to change the count or add to the existing count?
        </div>

      </div>
      <div class="modal-buttons">
        <div class="modal-button" (click)="setMode('cancel')">Cancel</div>
        <div class="modal-button" (click)="setMode('change')">Change Count</div>
        <div class="modal-button" (click)="setMode('add')">Add to Count</div>
      </div>
    </div>
  </div>

  <!-- EDIT TRANSFER SETTINGS -->
  <div *ngIf="purchase.settingsEdit" class="modal-wrapper" (mousedown)="cancelSettingsEdit()">
    <div class="modal-contents" (mousedown)="stopPropagation($event)">
      <div class="modal-header">Transfer Settings</div>
      <div class="modal-body">

        <div class="setting">
          <div class="label">Transfer Name</div>
          <div class="value">
            <input type="text" [(ngModel)]="purchase.settingsEdit.name" />
          </div>
        </div>

        <div class="setting">
          <div class="label">Notes</div>
          <div class="value">
            <textarea [(ngModel)]="purchase.settingsEdit.note"></textarea>
          </div>
        </div>

      </div>
      <div class="modal-buttons">
        <div class="modal-button" (click)="cancelSettingsEdit()">Cancel</div>
        <div class="modal-button" (click)="saveSettingsEdit()">Save</div>
      </div>
    </div>
  </div>

  <!-- EDIT TRANSFER ITEM COST -->
  <div *ngIf="purchase.costEdit" class="modal-wrapper" (mousedown)="cancelCostEdit()">
    <div class="modal-contents" (mousedown)="stopPropagation($event)">
      <div class="modal-header">{{purchase.costEdit.item.name}} {{purchase.costEdit.fieldLabel}}</div>
      <div class="modal-body">

        <div class="setting">
          <div class="label">{{purchase.costEdit.fieldLabel}}</div>
          <div class="value">
            <input type="text" [(ngModel)]="purchase.costEdit.cost" />
          </div>
        </div>

      </div>
      <div class="modal-buttons">
        <div class="modal-button" (click)="cancelCostEdit()">Cancel</div>
        <div class="modal-button" (click)="saveCostEdit()">Save</div>
      </div>
    </div>
  </div>

  <!-- CONFIRM TRANSFER STATUS CHANGE -->
  <div *ngIf="purchase.statusEdit" class="modal-wrapper" (mousedown)="cancelEditStatus()">
    <div class="modal-contents" (mousedown)="stopPropagation($event)">
      <div class="modal-header">Confirm Status Change</div>
      <div class="modal-body">

        <div class="msg">
          {{purchase.statusEdit.msg}}
        </div>

      </div>
      <div class="modal-buttons">
        <div class="modal-button" (click)="cancelEditStatus()">Cancel</div>
        <div class="modal-button" (click)="updateTransferStatus(purchase.statusEdit.status)">Yes</div>
      </div>
    </div>
  </div>

  <!-- NOTIFY SCANNED ITEM NOT ON PENDING TRANSFER -->
  <div *ngIf="purchase.missingBarcode" class="modal-wrapper" (mousedown)="cancelMissingBarcode()">
    <div class="modal-contents" (mousedown)="stopPropagation($event)">
      <div class="modal-header">Barcode Not On Transfer</div>
      <div class="modal-body">

        <div class="msg">
          <div>{{purchase.missingBarcode}}</div>
          <div>This barcode does not exist on this pending transfer. Do you still want to add it?</div>
        </div>

      </div>
      <div class="modal-buttons">
        <div class="modal-button" (click)="cancelMissingBarcode()">Cancel</div>
        <div class="modal-button" (click)="barcodeEntered(purchase.missingBarcode, true); cancelMissingBarcode()">Add</div>
      </div>
    </div>
  </div>

  <!-- NOTIFY TRANSFER ERRORS -->
  <div *ngIf="purchase.errors && purchase.errors.length > 0" class="modal-wrapper" (mousedown)="cancelMissingBarcode()">
    <div class="modal-contents" (mousedown)="stopPropagation($event)">
      <div class="modal-header">Transfer Error</div>
      <div class="modal-body">

        <div *ngFor="let error of purchase.errors" class="msg">
          <div>{{error.msg}}</div>
        </div>

      </div>
      <div class="modal-buttons">
        <div class="spacer"></div>
        <div class="modal-button" (click)="acknowledgeErrors()">Ok</div>
      </div>
    </div>
  </div>


  <div *ngIf="purchase && purchase.loading" class="loader">
    <svg class="processing" width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1639 1056q0 5-1 7-64 268-268 434.5t-478 166.5q-146 0-282.5-55t-243.5-157l-129 129q-19 19-45 19t-45-19-19-45v-448q0-26 19-45t45-19h448q26 0 45 19t19 45-19 45l-137 137q71 66 161 102t187 36q134 0 250-65t186-179q11-17 53-117 8-23 30-23h192q13 0 22.5 9.5t9.5 22.5zm25-800v448q0 26-19 45t-45 19h-448q-26 0-45-19t-19-45 19-45l138-138q-148-137-349-137-134 0-250 65t-186 179q-11 17-53 117-8 23-30 23h-199q-13 0-22.5-9.5t-9.5-22.5v-7q65-268 270-434.5t480-166.5q146 0 284 55.5t245 156.5l130-129q19-19 45-19t45 19 19 45z"/></svg>
  </div>

</ng-container>
